import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../../context/AuthContext/authContext";
import AppContext from "../../context/AppContext/appContext";
import ConnectLogo from "../../images/connect-logo.png";

// Material-UI
import { fade, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ListItemLink from "../ListItemLink";

// Feather Icons
import {
  Menu as MenuIcon,
  Image as ImageIcon,
  User as UserIcon,
  LogOut as LogoutIcon,
  MoreVertical as MoreVerticalIcon,
} from "react-feather";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => {
  const bottomLineColor = theme.palette.primary.contrastText;
  return {
    appBar: {
      background: theme.palette.primary.main,
      // background: `linear-gradient(to right, black, ${theme.palette.primary.main} 20%)`,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    menuButton: {
      marginRight: 24,
    },

    toolbarGutters: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },

    logo: {
      maxHeight: "32px",
    },

    grow: {
      flexGrow: 1,
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },

    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 0,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7),
      },
    },

    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      minHeight: "48px",
      // ...theme.mixins.toolbar
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        // width: 120,
        justifyContent: "space-between",
      },
    },

    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },

    headerIconMargin: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },

    headerTypographyMargin: {
      marginRight: theme.spacing(2),
      padding: "3px",
    },

    clientSelect: {
      position: "relative",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.1),
      },
      marginLeft: 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
      display: "flex",
      alignSelf: "center",
      padding: theme.spacing(1, 2),
      width: "100%",
      marginRight: theme.spacing(2),
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("md")]: {
        width: 300,
      },
      [theme.breakpoints.up("lg")]: {
        width: "max-content",
      },
    },

    clientSelectInputRoot: {
      [theme.breakpoints.up("md")]: {
        width: 300,
      },
      color: theme.palette.primary.contrastText,
    },

    clientSelectPopupIndicator: {
      color: theme.palette.primary.contrastText,
    },

    clientSelectUnderline: {
      "&:after": {
        borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '""',
        position: "absolute",
        right: 0,
        transform: "scaleX(0)",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }),
        pointerEvents: "none", // Transparent to the hover style.
      },
      "&:focused:after": {
        transform: "scaleX(1)",
      },
      "&:error:after": {
        borderBottomColor: theme.palette.primary.main,
        transform: "scaleX(1)", // error is always underlined in red
      },
      "&:before": {
        borderBottom: `1px solid ${bottomLineColor}`,
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '"\\00a0"',
        position: "absolute",
        right: 0,
        transition: theme.transitions.create("border-bottom-color", {
          duration: theme.transitions.duration.shorter,
        }),
        pointerEvents: "none", // Transparent to the hover style.
      },
      "&:hover:not(disabled):before": {
        borderBottom: `2px solid ${theme.palette.primary.contrastText} !important`,
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          borderBottom: `1px solid ${bottomLineColor} !important`,
        },
      },
      "&:disabled:before": {
        borderBottomStyle: "dotted",
      },
    },
  };
});

const TeksideAppBar = () => {
  const classes = useStyles();
  const { logout, accountManagement } = useContext(AuthContext);
  const {
    businessUnitList,
    selectedBusinessUnit,
    selectBusinessUnit,
    isBusinessUnitListLoading,
  } = useContext(AppContext);

  // URL location to figure out if we should render the autocomplete in the topbar.
  const location = useLocation();

  // Left drawer state
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Mobile right menu state
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerOpen = () => {
    setIsNavOpen(true);
  };

  const handleDrawerClose = () => {
    setIsNavOpen(false);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const mobileMenuId = "primary-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={accountManagement}>
        <IconButton aria-label="profile" color="inherit">
          <UserIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      {/*<MenuItem>*/}
      {/*  <IconButton aria-label="settings" color="inherit">*/}
      {/*    <SettingsIcon />*/}
      {/*  </IconButton>*/}
      {/*  <p>Settings</p>*/}
      {/*</MenuItem>*/}
      <MenuItem onClick={logout}>
        <IconButton aria-label="logout" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar classes={{ gutters: classes.toolbarGutters }}>
          {!isBusinessUnitListLoading && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={isNavOpen ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link
            to={"/compose"}
            underline="none"
            className={classes.title}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={ConnectLogo} alt="Connect Logo" className={classes.logo} />
          </Link>
          {/* Empty div to space menu options. */}
          <div className={classes.grow} />
          {location.pathname !== "/compose" &&
          location.pathname !== "/" &&
          selectedBusinessUnit &&
          businessUnitList.length > 1 ? (
            <Autocomplete
              margin="dense"
              disableClearable
              autoComplete
              blurOnSelect="mouse"
              id="client-select"
              className={classes.clientSelect}
              classes={{
                inputRoot: classes.clientSelectInputRoot,
                popupIndicator: classes.clientSelectPopupIndicator,
              }}
              options={businessUnitList.sort((a, b) =>
                a.BusinessUnitName.localeCompare(b.BusinessUnitName)
              )}
              groupBy={(option) => option.BusinessUnitName.charAt(0).toUpperCase()}
              getOptionLabel={(option) => {
                if (option === null) return "";
                else if (typeof option === "string") return option;
                else return option.BusinessUnitName;
              }}
              value={selectedBusinessUnit}
              onChange={(event, newClient) => {
                selectBusinessUnit(newClient);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        underline: classes.clientSelectUnderline,
                      },
                    }}
                    margin="none"
                  />
                );
              }}
            />
          ) : businessUnitList.length === 1 ? (
            <Typography className={classes.headerTypographyMargin}>
              {selectedBusinessUnit && selectedBusinessUnit.BusinessUnitName}
            </Typography>
          ) : null}
          {/* Desktop view of the header icons on the right */}
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="profile"
              color="inherit"
              onClick={accountManagement}
              className={classes.headerIconMargin}
            >
              <UserIcon />
            </IconButton>
            {/*<IconButton aria-label="settings" color="inherit">*/}
            {/*  <SettingsIcon />*/}
            {/*</IconButton>*/}
            <IconButton
              aria-label="logout"
              color="inherit"
              onClick={logout}
              className={classes.headerIconMargin}
            >
              <LogoutIcon />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVerticalIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {!isBusinessUnitListLoading && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isNavOpen,
            [classes.drawerClose]: !isNavOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: isNavOpen,
              [classes.drawerClose]: !isNavOpen,
            }),
          }}
        >
          <div className={classes.toolbar} />
          <Divider />
          <List>
            <ListItemLink primary="Image Manager" to="/compose/images" icon={<ImageIcon />} />
          </List>
        </Drawer>
      )}
    </>
  );
};

export default TeksideAppBar;
