import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import TeksideAppBar from "./components/TeksideAppBar";
import ClientSelectLayout from "./layouts/ClientSelectLayout";
import ImageManagerLayout from "./layouts/ImageManagerLayout";
import "./App.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight: "48px",
    // ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Router>
      <CssBaseline />
      <div className={classes.root}>
        <TeksideAppBar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/">
              <Redirect to="/compose" />
            </Route>
            <Route exact path="/compose" component={ClientSelectLayout} />
            <Route exact path="/compose/images" component={ImageManagerLayout} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
