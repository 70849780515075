import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Link,
  CircularProgress,
} from "@material-ui/core";
import AppContext from "../../context/AppContext/appContext";
import moment from "moment";
import axios from "axios";

const styles = (theme) => ({
  container: {
    border: "1px solid #e0e0e0",
    boxSizing: "border-box",
    width: "30%",
  },
  toolbarSpacing: {
    height: "43px",
    borderBottom: "1px solid #e0e0e0",
    backgroundColor: "rgb(250,250,250)",
  },
  headingSpacing: {
    height: "34px",
    borderBottom: "1px solid #e0e0e0",
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
  },
  headingText: {
    color: "rgba(0,0,0,0.87)",
    padding: "4px 4px 5px",
    fontSize: "14px",
    lineHeight: "27px",
  },
  detailsContainer: {
    padding: "14px 8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    overflowY: "auto",
  },
  imageLink: {
    width: "100%",
    display: "block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  imagePreview: {
    width: "90%",
  },
});

class ImageDetailsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoadingImageDetails: true,
      imageDetails: {},
      fileManagerHeight: window.innerHeight - 160,
    };
    this.openDrawer = this.openDrawer.bind(this);
    this.fetchFileDetails = this.fetchFileDetails.bind(this);
    this.setFileManagerHeight = this.setFileManagerHeight.bind(this);
    this.changeShownFileDetails = this.changeShownFileDetails.bind(this);
  }

  componentDidMount() {
    const { getHeight } = this.props;
    this.setState({
      fileManagerHeight: getHeight(),
    });
  }

  static contextType = AppContext;

  setFileManagerHeight = (height) => {
    this.setState({
      fileManagerHeight: height,
    });
  };

  openDrawer = () => {
    this.setState({ isOpen: true });
  };

  closeDrawer = () => {
    this.setState({ isOpen: false });
  };

  changeShownFileDetails = (filePath, baseUrlId) => {
    if (this.state.isOpen) {
      this.fetchFileDetails(filePath, baseUrlId);
    }
  };

  fetchFileDetails = async (filePath, baseUrlId) => {
    // Reset image Details.
    console.log(filePath);
    console.log(baseUrlId);
    this.setState({
      imageDetails: {},
      isLoadingImageDetails: true,
    });
    const { selectedBusinessUnit } = this.context;
    try {
      const res = await axios.get(process.env.REACT_APP_API_BASE_URL + "/api/v1/images", {
        params: {
          path: filePath,
          businessUnit: selectedBusinessUnit.BusinessUnitGuid,
          selectedBaseURLID: baseUrlId,
        },
      });
      const imageId = res.data.meta.results[0];
      const imageDetails = res.data.data[imageId].dataSet;
      this.setState({
        imageDetails,
        isLoadingImageDetails: false,
      });
    } catch (err) {
      this.setState({
        imageDetails: {},
        isLoadingImageDetails: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { isOpen, isLoadingImageDetails, imageDetails, fileManagerHeight } = this.state;
    if (isLoadingImageDetails && isOpen) {
      return (
        <div className={classes.container} style={{ maxHeight: fileManagerHeight }}>
          <div className={classes.toolbarSpacing} />
          <div className={classes.headingSpacing}>
            <div className={classes.headingContainer}>
              <span className={classes.headingText}>File Details</span>
              <IconButton aria-label="Close Details Pane" onClick={this.closeDrawer}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.detailsContainer} style={{ height: fileManagerHeight - 78 }}>
            <CircularProgress color="secondary" />
          </div>
        </div>
      );
    } else if (isOpen) {
      return (
        <div className={classes.container} style={{ maxHeight: fileManagerHeight }}>
          <div className={classes.toolbarSpacing} />
          <div className={classes.headingSpacing}>
            <div className={classes.headingContainer}>
              <span className={classes.headingText}>File Details</span>
              <IconButton aria-label="Close Details Pane" onClick={this.closeDrawer}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.detailsContainer} style={{ height: fileManagerHeight - 78 }}>
            {imageDetails && (
              <>
                <img
                  className={classes.imagePreview}
                  alt={imageDetails.ImageName}
                  src={imageDetails.url}
                />
                <Table size="small" aria-label="details-table" style={{ marginTop: "16px" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name:</TableCell>
                      <TableCell>{imageDetails.ImageName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>File Type:</TableCell>
                      <TableCell>{imageDetails.ImageFileExtension}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>URL:</TableCell>
                      <TableCell>
                        <Link href={imageDetails.url} target="_blank">
                          {imageDetails.url}
                        </Link>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>File Path:</TableCell>
                      <TableCell>{imageDetails.ImageFilePath}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Created:</TableCell>
                      <TableCell>
                        {moment(imageDetails.CreatedDateTime).format("YYYY-MM-DD HH:MM:SS")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

ImageDetailsPane.propTypes = {
  classes: PropTypes.object.isRequired,
  getHeight: PropTypes.func.isRequired,
};

export default withStyles(styles)(ImageDetailsPane);
