import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppContext from "./appContext";
import AuthContext from "../AuthContext/authContext";
import axios from "axios";

const AppState = (props) => {
  const { isAuthLoading, isAuthenticated } = useContext(AuthContext);

  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [isBusinessUnitListLoading, setIsBusinessUnitListLoading] = useState(true);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(undefined);

  const [selectedDomain, setSelectedDomain] = useState("");

  const fetchBusinessUnitList = async () => {
    // setClientList(mockClientList);
    // setIsClientListLoading(false);
    const businessUnits = [];
    try {
      const res = await axios.get(process.env.REACT_APP_API_BASE_URL + "/api/v1/businessUnits");
      res.data.meta.results.forEach((item) => {
        businessUnits.push(res.data.data[item]);
      });
      setBusinessUnitList(businessUnits);
      setIsBusinessUnitListLoading(false);
    } catch (err) {
      setIsBusinessUnitListLoading(false);
    }
  };

  const selectBusinessUnit = (businessUnit) => {
    setSelectedBusinessUnit(businessUnit);
    if (businessUnit.AllowedURLs.length > 0) {
      setSelectedDomain(businessUnit.AllowedURLs[0]);
    }
  };

  useEffect(() => {
    if (!isAuthLoading && isAuthenticated) {
      fetchBusinessUnitList();
    }
    if (!isAuthenticated) {
      setBusinessUnitList([]);
    }
  }, [isAuthLoading, isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        businessUnitList,
        isBusinessUnitListLoading,
        selectedBusinessUnit,
        selectedDomain,
        setSelectedDomain,
        selectBusinessUnit,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

AppState.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AppState;
