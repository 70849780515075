import React from "react";
import Dialog from "@material-ui/core/Dialog";

export default class SimpleModal extends React.Component {
  state = {
    open: false,
  };

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { children, dialogProps, noPadding } = this.props;
    const { open } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.closeModal}
        {...dialogProps}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={noPadding ? {} : { padding: "25px" }}>{children}</div>
      </Dialog>
    );
  }
}
