import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AuthContext from "./authContext";
import Keycloak from "keycloak-js";
import axios from "axios";

const AuthState = (props) => {
  const [keycloak, setKeycloak] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const initKeycloak = async () => {
    const keycloak = Keycloak({
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      url: process.env.REACT_APP_KEYCLOAK_URL,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
    });
    await keycloak.init({
      promiseType: "native",
      onLoad: "login-required",
      flow: "standard",
      checkLoginIframe: true,
    });
    setKeycloak(keycloak);
    axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
    // The request interceptor will add the bearer token to the request headers.
    axios.interceptors.request.use(
      (config) => {
        return new Promise((resolve, reject) => {
          keycloak
            .updateToken(5)
            .then((refreshed) => {
              if (refreshed) {
                config.headers["Authorization"] = `Bearer ${keycloak.token}`;
                axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
                return resolve(config);
              } else {
                return resolve(config);
              }
            })
            .catch(() => {
              return reject();
            });
        });
      },
      (error) => {
        Promise.reject(error);
      }
    );
    setIsAuthLoading(false);
    setIsAuthenticated(true);
  };

  const logout = () => {
    // eslint-disable-next-line
    delete axios.defaults.headers.common["Authorization"];
    keycloak.logout({ redirectUri: process.env.REACT_APP_LOGOUT_URL });
    setIsAuthenticated(false);
  };

  const accountManagement = () => {
    keycloak.accountManagement({ redirectUri: process.env.REACT_APP_LOGOUT_URL });
  };

  const updateToken = async () => {
    if (keycloak) {
      const isUpdated = await keycloak.updateToken(300);
      if (isUpdated) {
        axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
      }
    }
  };

  const getToken = () => {
    if (keycloak) {
      return keycloak.token;
    }
  };

  useEffect(() => {
    initKeycloak();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthLoading: isAuthLoading,
        isAuthenticated: isAuthenticated,
        logout,
        accountManagement,
        updateToken,
        getToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthState.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AuthState;
