import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles, Collapse, List, ListItem, ListItemText, Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  list: {
    margin: 0,
    paddingLeft: theme.spacing(4),
  },
});

class TipsPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.getIsOpen = this.getIsOpen.bind(this);
    this.openPane = this.openPane.bind(this);
    this.closePane = this.closePane.bind(this);
  }

  getIsOpen = () => {
    return this.state.isOpen;
  };

  openPane = () => {
    this.setState({ isOpen: true });
  };

  closePane = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { isOpen } = this.state;
    return (
      <Collapse in={isOpen}>
        <div
          style={{
            border: "1px solid #e0e0e0",
            borderBottom: "none",
            backgroundColor: "rgb(250, 250, 250)",
          }}
          className={classes.root}
        >
          <Typography variant="h6">Tips for using the Image Manager</Typography>
          <Typography variant="body1">
            Right clicking within the Image Manager is supported and provides great options for
            moving and manipulating your images:
          </Typography>
          <ul className={classes.list}>
            <li>
              <Typography variant="body1">
                <em>Right click on an image:</em> Open, Cut, Copy, Edit, Delete, Rename, Details,
                Copy URL
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <em>Right click on the file view pane:</em> Sort By, View, Refresh, Paste, New
                folder, Upload, Details, Select All
              </Typography>
            </li>
          </ul>
          <Typography variant="body1">
            To upload images, you can either select the upload option or drag files into the file
            pane from your computer.
          </Typography>
          <Typography variant="body1">
            To move one or multiple images to another folder, select the images and either cut then
            paste them into the folder or drag them to the folder in the folder tree view.
          </Typography>
          <Typography variant="body1">
            The maximum file size for uploaded images is 10MB.
          </Typography>
        </div>
      </Collapse>
    );
  }
}

TipsPane.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TipsPane);
