import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppContext from "../../context/AppContext/appContext";
import fuzzysort from "fuzzysort";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Search } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ClientTile from "../../components/ClientTile";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "150px",
  },
}));

const ClientSelectLayout = () => {
  const { isBusinessUnitListLoading, businessUnitList, selectBusinessUnit } = useContext(
    AppContext
  );
  const [alphabetizedList, setAlphabetizedList] = useState({});
  const [resultsLoading, setResultsLoading] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    if (!isBusinessUnitListLoading) {
      if (businessUnitList.length === 1) {
        selectBusinessUnit(businessUnitList[0]);
        history.push("/compose/images");
      }
    }
  }, [businessUnitList, isBusinessUnitListLoading]);

  const createAlphabetizedList = (list) => {
    const obj = {};

    for (let item of list) {
      const firstLetter = item.BusinessUnitName.charAt(0).toUpperCase();
      if (!obj.hasOwnProperty(firstLetter)) obj[firstLetter] = {};
      if (!obj[firstLetter].hasOwnProperty("data")) obj[firstLetter].data = [];
      obj[item.BusinessUnitName.charAt(0).toUpperCase()].data.push(item);
    }

    for (let letter of Object.keys(obj)) {
      obj[letter].data.sort((a, b) => a.BusinessUnitName.localeCompare(b.BusinessUnitName));
    }

    setAlphabetizedList(obj);
  };

  if (
    !isBusinessUnitListLoading &&
    businessUnitList.length !== 0 &&
    Object.keys(alphabetizedList).length === 0
  ) {
    createAlphabetizedList(businessUnitList);
  }

  const filterChange = async (searchText) => {
    const options = {
      threshold: -100000,
      allowTypo: false,
      key: "BusinessUnitName",
    };

    fuzzysort.goAsync(searchText, businessUnitList, options).then((results) => {
      let filteredResults = results.map((item) => item.obj);
      createAlphabetizedList(filteredResults);
      setResultsLoading(false);
    });

    setResultsLoading(true);
  };

  return (
    <>
      {resultsLoading && <>Loading...</>}
      {isBusinessUnitListLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" size={65} />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <TextField
              id="client-search-field"
              label="Search"
              fullWidth
              variant="filled"
              size="small"
              onChange={(e) => filterChange(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            {Object.keys(alphabetizedList)
              .sort()
              .map((item, index) => (
                <ExpansionPanel defaultExpanded key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography>{item}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Container maxWidth={false}>
                      {alphabetizedList[item].data.map((element) => (
                        <ClientTile
                          inline
                          key={element.BusinessUnitId}
                          item={element}
                          onClick={() => {
                            selectBusinessUnit(element);
                            history.push("/compose/images");
                          }}
                        />
                      ))}
                    </Container>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ClientSelectLayout;
