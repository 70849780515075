import React from "react";
import { TextField, Button, Grid, Typography } from "@material-ui/core";

export default function ImageResizeForm({ size, handleResize, handleCancel, ...props }) {
  const initialWidth = Number(size.width);
  const initialHeight = Number(size.height);

  const [disabled, setDisabled] = React.useState(true);
  const [maxHeight, setMaxHeight] = React.useState(initialHeight);
  const [maxWidth, setMaxWidth] = React.useState(initialWidth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const width = e.target.maxWidth.value;
    const height = e.target.maxHeight.value;
    handleResize(width, height);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const numberValue = Number(value);
    if (name === "maxWidth") {
      setMaxWidth(numberValue);
      if (
        maxHeight &&
        numberValue &&
        (maxHeight !== initialHeight || numberValue !== initialWidth)
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else if (name === "maxHeight") {
      setMaxHeight(numberValue);
      if (maxWidth && numberValue && (maxWidth !== size.width || numberValue !== size.height)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  return (
    <>
      <Typography component="p" gutterBottom>
        Set the max width and max height of the image to reduce the size of the image.
        <br />
        <br />
        <strong>Note: </strong>This does not change the aspect ratio of the image and you cannot
        increase the size of the image. Use the undo feature of the editor to reverse the size
        changes.
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              type="number"
              name="maxWidth"
              label="Max Width:"
              id="resizeWidth"
              value={maxWidth}
              InputProps={{
                inputProps: {
                  max: initialWidth,
                },
              }}
              required
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              type="number"
              name="maxHeight"
              label="Max Height"
              id="resizeHeight"
              value={maxHeight}
              InputProps={{
                inputProps: {
                  max: initialHeight,
                },
              }}
              required
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button variant="contained" type="submit" disabled={disabled}>
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleCancel}>Cancel</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
