import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, MenuItem, Typography } from "@material-ui/core";
import AppContext from "../../context/AppContext/appContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: "12px",
    minWidth: 125,
  },
  container: {
    display: "flex",
    alignItems: "center",
    marginLeft: "4px",
  },
  typography: {
    // paddingBottom: "4px",
  },
}));

const DomainSelector = ({ domainChangeCallback }) => {
  const { selectedBusinessUnit, selectedDomain, setSelectedDomain } = useContext(AppContext);
  const allowedDomains = selectedBusinessUnit ? selectedBusinessUnit.AllowedURLs : [];

  const classes = useStyles();

  const handleDomainChange = (event) => {
    domainChangeCallback();
    setSelectedDomain(event.target.value);
  };

  return (
    <div className={classes.container}>
      <Typography variant="button" style={{ paddingTop: "8px" }}>
        Domain:{" "}
      </Typography>
      <FormControl className={classes.formControl}>
        <Select id="domain-select" value={selectedDomain} onChange={handleDomainChange} autoWidth>
          {allowedDomains &&
            allowedDomains.map((domainObj) => (
              <MenuItem key={domainObj.ImageDomainId} value={domainObj}>
                {domainObj.ImageDomainName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DomainSelector;
