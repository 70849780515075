import { createMuiTheme } from "@material-ui/core/styles";

const baseTheme = {
  palette: {
    primary: {
      main: "#2f2e2e",
    },
    secondary: {
      main: "#DC3F26",
    },
  },
  typography: {
    fontSize: 12,
  },
  props: {
    MuiButton: {
      size: "small",
    },
    MuiFilledInput: {
      margin: "dense",
    },
    MuiFormControl: {
      margin: "dense",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiOutlinedInput: {
      margin: "dense",
    },
    MuiFab: {
      size: "small",
    },
    MuiTable: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
    },
    MuiToolbar: {
      variant: "dense",
    },
  },
};

/**
 * Generate a Material UI Theme object that can be passed into the
 * ThemeProvider context.
 *
 * @param {Object} [theme] - Any material-ui theme object that
 *        conforms to the theming guidelines. If no theme object is
 *        provided the default connect analyze theme will be
 *        generated. See: https://material-ui.com/customization/theming/
 * @return {Object} The generated material-ui theme.
 */
const generateTheme = (theme) => {
  return createMuiTheme({ ...baseTheme, ...theme });
};

export default generateTheme;
