import React from "react";
import PropTypes from "prop-types";
import SimpleModal from "../SimpleModal";
import ImageEditorContainer from "./ImageEditorContainer";
import AppContext from "../../context/AppContext/appContext";
import axios from "axios";
import { withSnackbar } from "notistack";

class ImageEditorPopUp extends React.Component {
  state = {
    fileDetails: {},
    image: { file: "", name: "" },
  };

  imageEditorRef = React.createRef();
  openImageEditor = async (image) => {
    if (image) {
      const nameWOExtension = image.name.substr(0, image.name.lastIndexOf("."));
      this.setState({
        fileDetails: image,
        image: {
          path: image["_fm_imageUrl"],
          name: nameWOExtension,
        },
      });
      this.modalRef.openModal();
    }
  };

  closeImageEditor = async () => {
    await this.setState({ image: { path: "", name: "" } });
    await this.modalRef.closeModal();
    await this.props.handleRefreshFiles();
  };

  saveImage = async (image) => {
    if (image) {
      const fileType = image.path.match(/[^:/]\w+(?=;|,)/)[0];
      const businessUnit = this.context.selectedBusinessUnit.BusinessUnitGuid;

      return await fetch(image.path)
        .then(async (res) => {
          return await res.blob();
        })
        .then((blob) => {
          const { fileDetails } = this.state;

          const filterPath = fileDetails.location.substr(
            fileDetails.location.indexOf("/"),
            fileDetails.location.lastIndexOf("/") + 1
          );

          const formData = new FormData();
          const file = new File([blob], `${image.name}.${fileType}`, {
            type: "image/" + fileType,
          });

          formData.append("uploadFiles", file);
          formData.append("businessUnit", businessUnit);
          formData.append("data", JSON.stringify(this.state.fileDetails));
          formData.append("path", filterPath);
          formData.append("action", "save");

          return axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/files/upload`, formData, {
              headers: {
                Accept: "*/*",
              },
            })
            .then((res) => {
              this.props.enqueueSnackbar("New image created.", { variant: "success" });
              return res;
            })
            .catch((err) => {
              this.props.enqueueSnackbar("Failed to upload copy of image", { variant: "error" });
              return err;
            });
        });
    }
  };

  render() {
    const { image } = this.state;
    return (
      <SimpleModal
        ref={(s) => (this.modalRef = s)}
        dialogProps={{
          fullWidth: true,
          maxWidth: "xl",
          disableBackdropClick: true,
        }}
        noPadding
      >
        <ImageEditorContainer
          editorRef={this.imageEditorRef}
          image={image}
          handleCancel={this.closeImageEditor}
          handleSave={this.saveImage}
        />
      </SimpleModal>
    );
  }
}

ImageEditorPopUp.contextType = AppContext;

ImageEditorPopUp.propTypes = {
  enqueueSnackbar: PropTypes.func,
  handleRefreshFiles: PropTypes.func,
};

export default withSnackbar(ImageEditorPopUp);
