import React from "react";
import PropTypes from "prop-types";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import { Button, TextField, Grid, Typography } from "@material-ui/core";
import SimpleModal from "../SimpleModal";
import ImageResizeForm from "./ImageResizeForm";
import Resizer from "react-image-file-resizer";
import base64DecodeUnicode from "../../utils/base64URItoBuffer";

const colors = {
  primary: "#ffffff",
  secondary: "#000000",
  tertiary: "#DC3F26",
  primaryText: "#000000",
  secondaryText: "#ffffff",
  tertiaryText: "#ffffff",
};

// default keys and styles
const customTheme = {
  "common.bi.image": "",
  "common.bisize.width": "0px",
  "common.bisize.height": "0px",
  "common.backgroundImage": "none",
  "common.backgroundColor": colors.primary,
  "common.border": "0px",

  // header
  "header.display": "none",

  // load button
  "loadButton.display": "none",

  // download button
  "downloadButton.display": "none",

  // icons default
  "menu.normalIcon.color": "#8a8a8a",
  "menu.activeIcon.color": "#555555",
  "menu.disabledIcon.color": "#434343",
  "menu.hoverIcon.color": "#e9e9e9",
  "submenu.normalIcon.color": "#8a8a8a",
  "submenu.activeIcon.color": "#e9e9e9",

  "menu.iconSize.width": "24px",
  "menu.iconSize.height": "24px",
  "submenu.iconSize.width": "32px",
  "submenu.iconSize.height": "32px",

  // submenu primary color
  "submenu.backgroundColor": colors.primary,
  "submenu.partition.color": colors.primaryText,

  // submenu labels
  "submenu.normalLabel.color": "#858585",
  "submenu.normalLabel.fontWeight": "lighter",
  "submenu.activeLabel.color": colors.primaryText,
  "submenu.activeLabel.fontWeight": "lighter",

  // checkbox style
  "checkbox.border": "1px solid #ccc",
  "checkbox.backgroundColor": "#fff",

  // rango style
  "range.pointer.color": colors.primaryText,
  "range.bar.color": "#666",
  "range.subbar.color": "#d1d1d1",

  "range.disabledPointer.color": "#414141",
  "range.disabledBar.color": "#282828",
  "range.disabledSubbar.color": "#414141",

  "range.value.color": colors.primaryText,
  "range.value.fontWeight": "lighter",
  "range.value.fontSize": "11px",
  "range.value.border": "1px solid #353535",
  "range.value.backgroundColor": "#151515",
  "range.title.color": colors.primaryText,
  "range.title.fontWeight": "lighter",

  // colorpicker style
  "colorpicker.button.border": "1px solid #1e1e1e",
  "colorpicker.title.color": colors.primaryText,
};

const ImageEditorContainer = ({ editorRef, image, handleCancel, handleSave, ...props }) => {
  //REFS
  let imageResizeModalRef;
  let verifyModalRef;
  let imageSaveModalRef;

  //STATES
  const [changesMade, setChangesMade] = React.useState(false);
  const [localImage, setLocalImage] = React.useState(image);
  const [canvasSize, setCanvasSize] = React.useState({});
  const [newImageName, setNewImageName] = React.useState(`Copy of ${image.name}`);
  const [saveAction, setSaveAction] = React.useState("");

  //FUNCTIONS
  const save = async () => {
    const editorInstance = editorRef.current.getInstance();
    const base64Image = await editorInstance.toDataURL();
    const results = await handleSave({ path: base64Image, name: newImageName });
    if (!(results instanceof Error)) {
      //Reset changes var after saving
      await closeImageSaveModal();
      await setChangesMade(false);
    }
  };

  const cancel = async () => {
    await handleCancel();
  };

  //HANDLERS
  const openVerifyCancel = () => {
    verifyModalRef.openModal();
  };

  const checkCancel = () => {
    if (changesMade) {
      openVerifyCancel();
    } else {
      cancel();
    }
  };

  const closeVerifyCancel = () => {
    verifyModalRef.closeModal();
  };

  const handleChange = (length) => {
    if (length > 0) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  };

  const openResizer = () => {
    const editorInstance = editorRef.current.getInstance();
    const size = editorInstance.getCanvasSize();
    setCanvasSize(size);

    imageResizeModalRef.openModal(canvasSize);
  };

  const closeResizer = () => {
    imageResizeModalRef.closeModal();
  };

  const handleResize = (width, height) => {
    const editorInstance = editorRef.current.getInstance();
    const imageFromEditor = editorInstance.toDataURL();
    const fileType = imageFromEditor.match(/[^:/]\w+(?=;|,)/)[0];
    const imageName = editorInstance.getImageName();
    const file = new File([base64DecodeUnicode(imageFromEditor)], imageName, {
      type: "image/" + fileType,
    });

    Resizer.imageFileResizer(
      file,
      width,
      height,
      fileType.toUpperCase(),
      100,
      0,
      (newImage) => {
        closeResizer();
        editorInstance.loadImageFromURL(newImage, imageName);
        setLocalImage({
          path: newImage,
          name: imageName,
        });
      },
      "base64"
    );
  };

  const openImageSaveModal = (action) => {
    if (action) {
      setSaveAction(action);
      imageSaveModalRef.openModal();
    }
  };

  const handleNameChange = (e) => {
    setNewImageName(e.target.value);
  };

  const closeImageSaveModal = async () => {
    await imageSaveModalRef.closeModal();
  };

  const handleSaveSubmit = async (e) => {
    e.preventDefault();
    if (saveAction === "save") {
      await save();
    } else if (saveAction === "saveAndClose") {
      await save();
      await cancel();
    }
  };

  return (
    <div>
      <div id="imageEditorToolbar">
        <Button variant="contained" onClick={openResizer}>
          Change Size
        </Button>
        <Button
          variant="contained"
          disabled={!changesMade}
          onClick={() => {
            openImageSaveModal("save");
          }}
        >
          Save A Copy
        </Button>
        <Button
          variant="contained"
          disabled={!changesMade}
          onClick={() => {
            openImageSaveModal("saveAndClose");
          }}
        >
          Save and Close
        </Button>
        <Button onClick={checkCancel}>Cancel</Button>
      </div>
      <div>
        <ImageEditor
          ref={editorRef}
          usageStatistics={false}
          includeUI={{
            loadImage: {
              path: localImage.path,
              name: localImage.name,
            },
            theme: customTheme,
            uiSize: {
              width: "100%",
              height: "768px",
            },
            menuBarPosition: "bottom",
          }}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          onUndoStackChanged={handleChange}
          cssMaxHeight={768}
          cssMaxWidth={700}
        />
        <SimpleModal
          id="verifyModal"
          ref={(s) => {
            verifyModalRef = s;
          }}
        >
          <p>There are unsaved changes, how do you wish to proceed?</p>
          <Button onClick={closeVerifyCancel} variant="contained">
            Go Back
          </Button>
          <Button onClick={cancel}>Discard changes</Button>
        </SimpleModal>
        <SimpleModal
          id="imageResizeModal"
          ref={(s) => {
            imageResizeModalRef = s;
          }}
          dialogProps={{
            fullWidth: false,
            maxWidth: "sm",
          }}
        >
          <ImageResizeForm
            size={canvasSize}
            handleResize={handleResize}
            handleCancel={closeResizer}
          />
        </SimpleModal>
        <SimpleModal
          id="imageSaveModal"
          ref={(s) => (imageSaveModalRef = s)}
          dialogProps={{
            fullWidth: false,
            maxWidth: "sm",
          }}
        >
          <form onSubmit={handleSaveSubmit}>
            <Grid container alignContent="center" alignItems="center" spacing={2} component="div">
              <Grid item xs={12} component="div">
                <Typography variant="subtitle2" component="span">
                  Please provide or change the filename:
                </Typography>
              </Grid>
              <Grid item xs={12} component="div">
                <TextField
                  name="setName"
                  id="setName"
                  value={newImageName}
                  required
                  onChange={handleNameChange}
                  fullWidth
                />
              </Grid>
              <Grid item component="div">
                <Button fullWidth variant="contained" type="submit">
                  Done
                </Button>
              </Grid>
              <Grid item component="div">
                <Button fullWidth onClick={closeImageSaveModal}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </SimpleModal>
      </div>
    </div>
  );
};

ImageEditorContainer.propTypes = {
  editorRef: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default ImageEditorContainer;
