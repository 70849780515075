import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "react-avatar";

const useStyles = makeStyles((theme) => {
  return {
    tile: {
      backgroundColor: "#f4f4f4",
      minHeight: "2rem",
      margin: "1rem 1rem 0 0",
      display: "inline-flex",
      userSelect: "none",
      flexFlow: "row nowrap",
      alignContent: "center",
      placeItems: "center end",
      transition: "0.3s all",
      borderRadius: "100px 0 0 100px",
      flex: "0 1 auto",
      img: {
        borderBottomLeftRadius: "5px",
        borderTopLeftRadius: "5px",
      },
      "&:focus": {
        border: "#0F62FE",
      },
      "&:hover": {
        backgroundColor: "#e5e5e5",
        cursor: "pointer",
        transform: "translateY(-2px)",
        boxShadow: "0 2px 10px 0 #DDD",
        flex: "1 1 auto",
        span: {
          maxWidth: "unset",
        },
      },
    },
    nameSpan: {
      margin: "0 1rem",
      textOverflow: "ellipsis",
      lineHeight: "1.3",
      overflowX: "hidden",
      whiteSpace: "nowrap",
      width: "20ch",
    },
  };
});

const ClientTile = ({ item, onClick }) => {
  const classes = useStyles();
  const text = item.BusinessUnitName || "Undefined";

  return (
    <Tooltip title={item.BusinessUnitName} aria-label={item.BusinessUnitName} arrow>
      <div
        data-tile="clickable"
        className={classes.tile}
        onClick={onClick}
        data-tip={item.BusinessUnitName}
        data-for={item.BusinessUnitId}
      >
        <span>
          <Avatar
            style={{ borderRadius: "5px" }}
            src={item.BusinessUnitImage}
            name={item.BusinessUnitName}
            size="48"
            delayShow={1000}
            maxInitials={2}
            round
          />
        </span>
        <span className={classes.nameSpan}>{text}</span>
      </div>
    </Tooltip>
  );
};

ClientTile.propTypes = {
  item: PropTypes.shape({
    BusinessUnitName: PropTypes.string.isRequired,
    BusinessUnitId: PropTypes.number.isRequired,
    BusinessUnitImage: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};

export default ClientTile;
