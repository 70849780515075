import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AuthState from "./context/AuthContext/AuthState";
import AppState from "./context/AppContext/AppState";
import generateTheme from "./utils/generateTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";

const theme = generateTheme();
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        autoHideDuration={3000}
        maxSnack={3}
        action={(key) => (
          <IconButton
            size="small"
            color="inherit"
            aria-label="close notification"
            onClick={onClickDismiss(key)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <AuthState>
          <AppState>
            <App />
          </AppState>
        </AuthState>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
