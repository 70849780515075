import React, { useContext, useEffect } from "react";
import AppContext from "../../context/AppContext/appContext";
import AuthContext from "../../context/AuthContext/authContext";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { DomainSelector, ImageDetailsPane } from "../../components/ImageManager";
import {
  FileManagerComponent,
  DetailsView,
  NavigationPane,
  Toolbar,
  Inject,
} from "@syncfusion/ej2-react-filemanager";
import { ToolbarComponent } from "@syncfusion/ej2-react-navigations";
import ImageEditorPopUp from "../../components/ImageEditor/ImageEditorPopUp";
import { useSnackbar } from "notistack";
import TipsPane from "../../components/ImageManager/TipsPane";

const ImageManagerLayout = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const fileManagerId = "file";

  //REFS
  let imageDetailsPaneRef;
  let imageEditorPopUpRef;
  let imageManagerRef;
  let tipsPaneRef;

  // HOOKS
  const { selectedBusinessUnit, selectedDomain } = useContext(AppContext);
  const { updateToken, getToken, isAuthLoading, isAuthenticated, logout } = useContext(AuthContext);

  /**
   * Send them back to client select screen if they haven't selected
   * a client.
   */
  const history = useHistory();
  if (!selectedBusinessUnit) {
    history.push("/compose");
  }

  //IMAGE EDITOR
  const handleImageEditorOpen = (image) => {
    imageEditorPopUpRef.openImageEditor(image);
  };

  //==================
  // FILE MANAGER EVENT HANDLERS
  //=================
  // File Manager Height
  let initialFileManagerHeight = window.innerHeight - 160;

  const handleWindowSizeChange = () => {
    // Janky as hell, but its working.
    if (tipsPaneRef && tipsPaneRef.getIsOpen()) {
      if (imageDetailsPaneRef) {
        imageDetailsPaneRef.setFileManagerHeight(window.innerHeight - 160 - 180);
      }
      if (imageManagerRef) {
        imageManagerRef.height = window.innerHeight - 160 - 180;
      }
    } else {
      if (imageDetailsPaneRef) {
        imageDetailsPaneRef.setFileManagerHeight(window.innerHeight - 160);
      }
      if (imageManagerRef) {
        imageManagerRef.height = window.innerHeight - 160;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => window.removeEventListener("resize", handleWindowSizeChange);
  }, []);

  const getHeight = () => {
    if (imageManagerRef) {
      return imageManagerRef.height;
    }
    return window.innerHeight - 160;
  };

  //  CONTEXT MENU
  const menuClick = ({ fileDetails, item, ...args }) => {
    if (item.text === "Edit") {
      if (fileDetails.length === 1) {
        handleImageEditorOpen(fileDetails[0]);
      }
    }
    if (item.id === fileManagerId + "_cm_copyurl") {
      if (fileDetails.length === 1) {
        navigator.clipboard
          .writeText(fileDetails[0].fullImageUrl)
          .then(() => {
            enqueueSnackbar("URL copied to clipboard!", { variant: "success" });
          })
          .catch(() => {
            enqueueSnackbar("Failed to copy URL to clipboard!", { variant: "error" });
          });
      }
    }
  };

  const menuOpen = (args) => {
    if (imageManagerRef) {
      const currentSelectedFiles = imageManagerRef.getSelectedFiles();
      if (currentSelectedFiles.length > 1) {
        imageManagerRef.disableMenuItems(["Edit", "CopyURL"]);
      }

      for (const i in args.items) {
        if (args.items[i].id === fileManagerId + "_cm_edit") {
          args.items[i].iconCss = "e-icons e-edit-icon1";
        }
        if (args.items[i].id === fileManagerId + "_cm_copyurl") {
          args.items[i].iconCss = "e-icons e-copy-hyperlink-icon";
          args.items[i].text = "Copy URL";
        }
      }
    }
  };
  //TOOLBAR

  const toolbarCreate = (args) => {
    for (let i = 0; i < args.items.length; i++) {
      if (args.items[i].id === fileManagerId + "_tb_edit") {
        args.items[i].visible = false;
        args.items[i].prefixIcon = "e-icons e-edit-icon1";
        args.items[i].showTextOn = "Both";
      }
      if (args.items[i].id === fileManagerId + "_tb_tips") {
        args.items[i].align = "Right";
        args.items[i].tooltipText = "Usage Tips";
      }
    }
  };

  const toolbarClick = ({ item }) => {
    if (item.text === "Edit" && imageManagerRef) {
      const selectedFiles = imageManagerRef.getSelectedFiles();
      if (selectedFiles.length === 1) {
        handleImageEditorOpen(selectedFiles[0]);
      }
    }
    if (item.text === "Tips" && imageManagerRef) {
      if (tipsPaneRef) {
        if (tipsPaneRef.getIsOpen() === true) {
          if (imageDetailsPaneRef) {
            imageDetailsPaneRef.setFileManagerHeight(window.innerHeight - 160);
          }
          imageManagerRef.height = window.innerHeight - 160;
          tipsPaneRef.closePane();
        } else {
          if (imageDetailsPaneRef) {
            imageDetailsPaneRef.setFileManagerHeight(window.innerHeight - 160 - 180);
          }
          imageManagerRef.height = window.innerHeight - 160 - 180;
          tipsPaneRef.openPane();
        }
      }
    }
  };

  //FILES

  const fileSelect = ({ action, fileDetails, ...args }) => {
    // Image Editor
    if (imageManagerRef) {
      const currentSelectedFiles = imageManagerRef.getSelectedFiles();
      const toolbarEditItemIndex = imageManagerRef.getToolbarItemIndex("Edit");
      const toolbarRef = imageManagerRef.toolbarModule.toolbarObj;

      //Hide/Show toolbar button based on selection of a file (not folder)
      if (currentSelectedFiles.length === 1) {
        if (fileDetails.isFile) {
          toolbarRef.hideItem(toolbarEditItemIndex, false);
        } else {
          toolbarRef.hideItem(toolbarEditItemIndex, true);
        }
      } else {
        toolbarRef.hideItem(toolbarEditItemIndex, true);
      }
    }
    // TODO: Add && fileDetails.isFile
    if (action === "select") {
      if (imageDetailsPaneRef) {
        imageDetailsPaneRef.changeShownFileDetails(
          fileDetails.location,
          selectedDomain.ImageDomainId
        );
      }
      // TODO - Change this to show folder Details.
    }
    // else if (action === "select" && !fileDetails.isFile) {
    //   if (imageDetailsPaneRef) {
    //     imageDetailsPaneRef.closeDrawer();
    //   }
    // }
  };

  const handleRefreshFiles = () => {
    if (imageManagerRef) {
      imageManagerRef.refreshFiles();
    }
  };

  const domainChangeCallback = () => {
    if (imageDetailsPaneRef) {
      imageDetailsPaneRef.closeDrawer();
    }
  };

  const beforeSend = (args) => {
    if (isAuthenticated && !isAuthLoading) {
      const data = JSON.parse(args.ajaxSettings.data);
      if (args.action === "Upload") {
        if (selectedBusinessUnit) {
          data.push({ businessUnit: selectedBusinessUnit.BusinessUnitGuid });
          data.push({ selectedBaseURLID: selectedDomain.ImageDomainId });
        }
      } else if (selectedBusinessUnit) {
        data.businessUnit = selectedBusinessUnit.BusinessUnitGuid;
        data.selectedBaseURLID = selectedDomain.ImageDomainId;
      }
      args.ajaxSettings.data = JSON.stringify(data);
      args.ajaxSettings.beforeSend = (args) => {
        updateToken();
        const token = getToken();
        args.httpRequest.setRequestHeader("Authorization", `Bearer ${token}`);
      };
    } else {
      args.cancel = true;
    }
  };

  const beforeImageLoad = (args) => {
    if (selectedBusinessUnit) {
      args.imageUrl = args.imageUrl + `&businessUnit=${selectedBusinessUnit.BusinessUnitGuid}`;
    }
  };

  const beforePopupOpen = (args) => {
    console.log(args);
    if (args.popupName === "Image Preview") {
      args.cancel = true;
      if (imageManagerRef) {
        const currentSelectedFile = imageManagerRef.getSelectedFiles()[0];
        imageDetailsPaneRef.fetchFileDetails(
          currentSelectedFile.location,
          selectedDomain.ImageDomainId
        );
        imageDetailsPaneRef.openDrawer();
      }
    }
  };

  const onFailure = (args) => {
    if (args.error.code === "401") {
      logout();
    }
  };

  // FileManager Settings
  const ajaxSettings = {
    url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/files`,
    uploadUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/files/upload`,
    getImageUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1/images/getImage`,
  };

  const contextMenuSettings = {
    file: ["Open", "|", "Cut", "Copy", "|", "Edit", "Delete", "Rename", "|", "Details", "CopyURL"],
    folder: ["Open", "|", "Cut", "Copy", "Paste", "|", "Delete", "Rename", "|", "Details"],
  };

  const toolbarSettings = {
    items: [
      "NewFolder",
      "Upload",
      "Cut",
      "Copy",
      "Paste",
      "Edit",
      "Delete",
      "Rename",
      "SortBy",
      "Refresh",
      "Selection",
      "View",
      "Details",
      "Tips",
    ],
    visible: true,
  };

  const uploadSettings = {
    autoUpload: true,
    minFileSize: 1,
    maxFileSize: process.env.REACT_APP_MAX_UPLOAD_SIZE,
    allowedExtensions: ".jpg,.jpeg,.png,.gif",
  };

  const detailsViewSettings = {
    columns: [
      {
        field: "name",
        headerText: "Name",
        minWidth: 120,
        template: "${name}",
        customAttributes: { class: "e-fe-grid-name" },
      },
      {
        field: "fullImageUrl",
        headerText: "URL",
        minWidth: 120,
        template: "${fullImageUrl}",
      },
      {
        field: "_fm_modified",
        headerText: "DateModified",
        type: "dateTime",
        format: "MMMM dd, yyyy HH:mm",
        minWidth: 120,
        width: "190",
      },
      {
        field: "size",
        headerText: "Size",
        minWidth: 90,
        width: "110",
        template: "${size}",
      },
    ],
  };

  return (
    <div style={{ display: "flex" }}>
      <Paper style={{ flexGrow: 1 }}>
        <TipsPane ref={(s) => (tipsPaneRef = s)} />
        <ToolbarComponent
          id="domain-toolbar"
          style={{ border: "1px solid #e0e0e0", borderBottom: "none" }}
        >
          <div>
            <div>
              <DomainSelector domainChangeCallback={domainChangeCallback} />
            </div>
          </div>
        </ToolbarComponent>
        <div className="control-section" style={{ display: "flex" }}>
          <FileManagerComponent
            id={fileManagerId}
            view="LargeIcons"
            ref={(s) => (imageManagerRef = s)}
            height={initialFileManagerHeight}
            allowDragAndDrop
            ajaxSettings={ajaxSettings}
            contextMenuSettings={contextMenuSettings}
            toolbarSettings={toolbarSettings}
            uploadSettings={uploadSettings}
            detailsViewSettings={detailsViewSettings}
            menuClick={menuClick}
            menuOpen={menuOpen}
            toolbarCreate={toolbarCreate}
            toolbarClick={toolbarClick}
            fileSelect={fileSelect}
            beforePopupOpen={beforePopupOpen}
            beforeSend={beforeSend}
            beforeImageLoad={beforeImageLoad}
            failure={onFailure}
          >
            <Inject services={[NavigationPane, DetailsView, Toolbar]} />
          </FileManagerComponent>
          <ImageDetailsPane ref={(s) => (imageDetailsPaneRef = s)} getHeight={getHeight} />
        </div>
        <ImageEditorPopUp
          ref={(s) => (imageEditorPopUpRef = s)}
          handleRefreshFiles={handleRefreshFiles}
        />
      </Paper>
    </div>
  );
};
export default ImageManagerLayout;
